<template>
  <div class="operate">
    <img src="./img/operate.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "operate",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.operate {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
